<template>
  <div class="add_teacher">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>赢积分设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="addFromRef" :model="addFrom" :rules="addFromRules" label-width="150px">
        <el-form-item label="温馨提示:" label-width="100px">
       <!-- 文本域 -->
         <el-input
             type="textarea"
             :rows="3"
             placeholder="请输入内容"
             style="width: 300px"
             v-model="addFrom.detail"
          ></el-input>
     
      </el-form-item>
      <el-form-item label="完善真实姓名所得积分:" prop="trueName_set">
        <el-input-number
          :min="0"
          step-strictly
          :step="1"
          v-model.number="addFrom.trueName_set"
          style="margin-right: 15px"
        ></el-input-number>
       
     
      </el-form-item>
      <el-form-item label="完善身份证所得积分:" prop="idCard_set">
        <el-input-number
          :min="0"
          step-strictly
          :step="1"
          v-model.number="addFrom.idCard_set"
          style="margin-right: 15px"
        ></el-input-number>
       
     
      </el-form-item>
      <el-form-item label="完善年龄所得积分:" prop="age_set">
        <el-input-number
          :min="0"
          step-strictly
          :step="1"
          v-model.number="addFrom.age_set"
          style="margin-right: 15px"
        ></el-input-number>
       
     
      </el-form-item>
      <el-form-item label="完善生日所得积分:" prop="birthday_set">
        <el-input-number
          :min="0"
          step-strictly
          :step="1"
          v-model.number="addFrom.birthday_set"
          style="margin-right: 15px"
        ></el-input-number>
       
     
      </el-form-item>
      <!-- <el-form-item label="完善手机号所得积分:" prop="phone_set">
        <el-input-number
          :min="0"
          step-strictly
          :step="1"
          v-model.number="addFrom.phone_set"
          style="margin-right: 15px"
        ></el-input-number>
       
     
      </el-form-item> -->
      <el-form-item label="完善地址所得积分:" prop="address_set">
        <el-input-number
          :min="0"
          step-strictly
          :step="1"
          v-model.number="addFrom.address_set"
          style="margin-right: 15px"
        ></el-input-number>
       
     
      </el-form-item>
      <el-form-item label="完善所属行业所得积分:" prop="industry_set">
        <el-input-number
          :min="0"
          step-strictly
          :step="1"
          v-model.number="addFrom.industry_set"
          style="margin-right: 15px"
        ></el-input-number>
       
     
      </el-form-item>
      <el-form-item label="完善学历所得积分:" prop="education_set">
        <el-input-number
          :min="0"
          step-strictly
          :step="1"
          v-model.number="addFrom.education_set"
          style="margin-right: 15px"
        ></el-input-number>
       
     
      </el-form-item>
      <el-form-item label="完善兴趣爱好所得积分:" prop="interest_set">
        <el-input-number
          :min="0"
          step-strictly
          :step="1"
          v-model.number="addFrom.interest_set"
          style="margin-right: 15px"
        ></el-input-number>
       
     
      </el-form-item>
   


      <el-form-item>
        <el-button type="success" @click="submitForm">保存</el-button>
        <!-- <el-button  type="primary" @click="goBack">返回</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getPointsInfo,pointsInfoSet } from "../../api/vip.js";
export default {
  data() {
    return {
      isSubmit: false,
      addFrom: {
        trueName_set: 0,
        idCard_set: 0,
        age_set: 0,
        birthday_set: 0,
        // phone_set: 0,
        address_set: 0,
        industry_set: 0,
        interest_set: 0,
        detail: "",
        education_set: 0,
      },
      addFromRules: {
        trueName_set: [{ required: true, message: "请输入完善真实姓名所得积分", trigger: "blur" }],
        idCard_set: [{ required: true, message: "请输入完善身份证所得积分", trigger: "blur" }],
        age_set: [{ required: true, message: "请输入完善年龄所得积分", trigger: "blur" }],
        birthday_set: [{ required: true, message: "请输入完善生日所得积分", trigger: "blur" }],
        // phone_set: [{ required: true, message: "请输入完善手机号所得积分", trigger: "blur" }],
        address_set: [{ required: true, message: "请输入完善地址所得积分", trigger: "blur" }],
        industry_set: [{ required: true, message: "请输入完善所属行业所得积分", trigger: "blur" }],
        interest_set: [{ required: true, message: "请输入完善兴趣爱好所得积分", trigger: "blur" }],
        education_set: [{ required: true, message: "请输入完善学历所得积分", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    async getDetails() {
      const { data } = await getPointsInfo();
      if (data.code == 0) {
        Object.assign(this.addFrom, data.data);
        console.log(this.addFrom);
        // this.addFrom.shopTime = data.data.shopTime;
        // this.addFrom.userTime = data.data.userTime;
      }
    },
    submitForm() {
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) return;
        if (this.isSubmit) return;
        this.isSubmit = true;
        const { data } = await pointsInfoSet(this.addFrom);
        if (data.code == 0) {
          this.$message.success(data.msg);
          this.getDetails();
        } else {
          this.$message.warning(data.msg);
        }
        setTimeout(() => {
          this.isSubmit = false;
        }, 1500);
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.el-breadcrumb {
  height: 35px;
  border-bottom: 1px solid rgb(192, 191, 191);
  margin-bottom: 30px;
}
.item {
  display: flex;
}
.avatar-uploader-icon {
  border: 1px dashed #eee;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
}
.box-card {
  padding-bottom: 50px;
}
</style>
